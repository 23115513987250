import { Outlet } from "react-router-dom";

import BodyLayout from "./BodyLayout";
import { HeaderLayout } from "./HeaderLayout";

export default function Layout() {
    return (
        <div className="dashboard-layout h-full flex flex-col">
            {process.env.REACT_APP_APP_ENV === 'development' && 
                <div className="text-center w-full text-red-500 font-bold fixed !z-[10000] top-0">This is currently in testing. Please submit any feedback or bugs into the Support tab</div>
            }
            <HeaderLayout />
            <BodyLayout>
                <Outlet />
            </BodyLayout>
        </div>
    )
}
