import { useDispatch } from 'react-redux';

import { 
  Button, 
  FormControlLabel, 
  Radio, 
  RadioGroup, 
  TextField 
} from '@mui/material'

import { toast } from 'react-toastify';

import { updateProgess } from '../../redux/reducers/progressSlice';
import { useResetPasswordMutation } from '../../services/password-reset';
import { useUpdateUserMutation } from '../../services/user';
import { getUser } from '../../utils/jwt-helpers';
import { UserType } from '../sign-up'

export default function Account() {
  const [updateUser, { isUpdating }] = useUpdateUserMutation();
  const [resetPassword, { isPasswordReseting }] = useResetPasswordMutation()
  const dispatch = useDispatch();

  let user = getUser(localStorage.getItem('accessToken'))

  const handleUserInfo = async (e) => {
    e.preventDefault();
    try {
      let result = await updateUser({
        body: {
          Username: `${e.target.firstName.value} ${e.target.lastName.value}`,
          Type: e.target.userType.value
        }
      }).unwrap();
      if (result.success) {
        toast.success('Updated successfully')
        localStorage.setItem('accessToken', result.accessToken)
        localStorage.setItem('refreshToken', result.refreshToken)
        window.location.reload()
      } else {
        toast.error('Failed updating')
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  };

  const handleChangeEmail = async (e) => {
    e.preventDefault();
    if (e.target.newEmail.value !== e.target.confirmEmail.value) {
      toast.error('Emails do not match.')
    }
    try {
      let result = await updateUser({
        body: {
          Email: e.target.newEmail.value,
        }
      }).unwrap();
      if (result.success) {
        toast.success('Updated successfully')
        localStorage.setItem('accessToken', result.accessToken)
        localStorage.setItem('refreshToken', result.refreshToken)
        window.location.reload()
      } else {
        toast.error('Failed updating')
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (e.target.newPassword.value !== e.target.confirmPassword.value) {
      toast.error('Passwords do not match.')
    }
    try {
      let result = await resetPassword({
        body: {
          userId: user.UserID,
          password: e.target.newPassword.value,
        },
      }).unwrap()
      if (result.success) {
        toast.success('Updated successfully')
      } else {
        toast.error('Failed updating')
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  };

  if (isUpdating || isPasswordReseting) {
    dispatch(updateProgess(true))
  } else {
    dispatch(updateProgess(false))
  }

  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
      <form className='shadow p-5 rounded-lg user-info space-y-5' onSubmit={handleUserInfo}>
        <div className='text-xl font-bold'>User information</div>
        <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 gap-5'>
          <TextField
            type="text"
            color="primary"
            size="small"
            id="firstName"
            name="firstName"
            placeholder=""
            label="First Name"
            variant="outlined"
            required
            defaultValue={user.Username.split(" ")[0]}
          />
          <TextField
            type="text"
            color="primary"
            size="small"
            id="lastName"
            name="lastName"
            placeholder=""
            label="Last Name"
            variant="outlined"
            required
            defaultValue={user.Username.split(" ")[1]}
          />
        </div>
        <div className=''>
          <div className='text-lg'>Account Type</div>
          <div className='flex'>
            <RadioGroup
              row
              name="userType"
              defaultValue={user.Type}
            >
              <FormControlLabel
                value={UserType.Daily}
                control={<Radio color="primary" />}
                label="Daily User"
              />
              <FormControlLabel
                value={UserType.Business}
                control={<Radio color="primary" />}
                label="Business User"
              />
            </RadioGroup>
          </div>
        </div>
        <Button
          className="bg-primary hover:bg-prmary"
          type="submit"
          variant="contained"
        >
          Save User Information
        </Button>
      </form>
      {/* <form className='shadow p-5 rounded-lg' onSubmit={handleChangeEmail}>
        <div className='text-xl font-bold'>Change Email</div>
        <div className='mt-5 flex flex-col space-y-5'>
          <div className=''>
            <TextField
              type="text"
              color="primary"
              size="small"
              id="newEmail"
              name="newEmail"
              placeholder=""
              label="New Email"
              variant="outlined"
              className='w-full'
              required
            />
            <div>Your current email is <span className='italic font-bold'>{user.Email}</span></div>
          </div>
          <div>
            <TextField
              type="text"
              color="primary"
              size="small"
              id="confirmEmail"
              name="confirmEmail"
              placeholder=""
              label="Confirm New Email"
              variant="outlined"
              className='w-full'
              required
            />
          </div>
          <Button
            className="bg-primary hover:bg-prmary w-fit"
            type="submit"
            variant="contained"
          >
            Update email
          </Button>
        </div>
      </form> */}
      <form className='shadow p-5 rounded-lg' onSubmit={handleChangePassword}>
        <div className='text-xl font-bold'>Change Password</div>
        <div className='mt-5 flex flex-col space-y-5'>
          <TextField
            type="password"
            color="primary"
            size="small"
            id="newPassword"
            name="newPassword"
            placeholder=""
            label="New Password"
            variant="outlined"
            className='w-full'
            required
          />
          <TextField
            type="password"
            color="primary"
            size="small"
            id="confirmPassword"
            name="confirmPassword"
            placeholder=""
            label="Confirm Password"
            variant="outlined"
            className='w-full'
            required
          />
          <Button
            className="bg-primary hover:bg-prmary w-fit"
            type="submit"
            variant="contained"
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  )
}
