import { horizonPredictApi } from '../../redux/apis/horizonPredict'

const userService = horizonPredictApi.injectEndpoints({
  endpoints: (build) => ({
    jwtLogin: build.mutation({
      query: ({ params, body }) => ({
        url: 'jwt.login',
        method: 'POST',
        body,
      }),
    }),
    jwtSignUp: build.mutation({
      query: ({ params, body }) => ({
        url: 'jwt.signup',
        method: 'POST',
        body,
      }),
    }),
    updateUser: build.mutation({
      query: ({ params, body }) => ({
        url: 'user.update',
        method: 'POST',
        body,
      }),
    }),
    submitContactForm: build.mutation({
      query: ({ params, body }) => ({
        url: 'contact.form',
        method: 'POST',
        body,
      }),
    }),
    submitSupportForm: build.mutation({
      query: ({ params, body }) => ({
        url: 'support.form',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useJwtLoginMutation,
  useJwtSignUpMutation,
  useUpdateUserMutation,
  useSubmitContactFormMutation,
  useSubmitSupportFormMutation
} = userService
