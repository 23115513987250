import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'

import { CheckBox, CheckBoxOutlineBlank, ExpandMoreOutlined, HelpOutlineOutlined } from '@mui/icons-material'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import * as _ from 'lodash'

import {
  useAddInItemListMutation,
  useCreateInventoryMutation,
  useGetInvCategoryQuery,
  useGetInventoryQuery,
  useGetItemListQuery,
} from '../../services/inventory'
import InventoryCard from '../../components/inventory/InventoryCard'
import { updateProgess } from '../../redux/reducers/progressSlice'
import { useGetMeasurementQuery } from '../../services/measurement'
import { capitalizeFirstLetter } from '../../utils/string-helpers'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

export default function Cupboard() {
  const [categoryRequired, setCategoryRequired] = useState(false)
  const [newItem, setNewItem] = useState()
  const { data: { measurement } = {}, isLoading } = useGetMeasurementQuery()

  const {
    data: { inventory } = {},
    error: inventoryError,
    isLoading: isInventoryLoading,
  } = useGetInventoryQuery() // just for demo userId = 1

  const {
    data: { category } = {},
    error: invCategoryError,
    isLoading: isInvCatLoading,
  } = useGetInvCategoryQuery() // just for demo userId = 1

  const { data: { itemList } = {}, isLoading: isItemListLoading } =
    useGetItemListQuery()

  const [createInventory, { isLoading: isCreatingInv }] =
    useCreateInventoryMutation()

  const [addInItem, { isLoading: isAddingInItem }] = useAddInItemListMutation();

  const [filteredInventory, setFilteredInventory] = useState()
  const [header, setHeader] = useState('All Groceries')

  const dispatch = useDispatch()

  useEffect(() => {
    if (inventory) {
      setFilteredInventory(inventory)
    }
  }, [inventory])

  const handleCategoryRequiredChecked = (e) => {
    setCategoryRequired(e.target.checked)
  }

  const handleAddInventory = async (e) => {
    e.preventDefault()
    const inventory = {
      InvName: e.target.itemName.value,
      InvQuantity: parseFloat(e.target.quantity.value),
      InvCost: parseFloat(e.target.cost.value),
      InvMeasure: parseInt(e.target.measurement.value),
      CatName: e.target.category?.value,
      DisplayName: '',
    }

    try {
      let result = await createInventory({
        body: { inventory },
      }).unwrap()
      if (result.success) {
        toast.success('Inventory added')
      } else {
        toast.error(result.message)
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  }

  const handleReqItem = async (e) => {
    if (newItem.trim().length === 0) {
      toast.error('Please fill in Item Name')
      return
    } 
    try {
      let result = await addInItem({
        body: { item: newItem },
      }).unwrap()
      if (result.success) {
        toast.success('Your request has been sent to the support.')
      } else {
        toast.error(result.message)
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  }

  const handleChangeCategory = (event, newValue) => {
    const catIDs = _.map(newValue, 'CatID')
    if (catIDs.length > 0) {
      const filteredItems = _.filter(inventory, (item) =>
        _.includes(catIDs, item.CatID)
      )
      console.log('newValue: ', inventory, newValue)
      setFilteredInventory(filteredItems)
      setHeader(
        newValue.map((item) => capitalizeFirstLetter(item.CatName)).join(', ')
      )
    } else {
      setFilteredInventory(inventory)
      setHeader('All Inventory')
    }
  }

  if (isCreatingInv) {
    dispatch(updateProgess(true))
  } else {
    dispatch(updateProgess(false))
  }

  if (!filteredInventory || !itemList || !category) {
    dispatch(updateProgess(true))
    return <div className="text-3xl">Loading</div>
  } else {
    dispatch(updateProgess(false))
  }

  return (
    <div className="inventory">
      <form
        className="w-full bg-gray-50 shadow rounded-lg p-5"
        onSubmit={handleAddInventory}
      >
        <div className="text-2xl font-bold inventory-input">
          Grocery Input
        </div>
        <div className="shadow bg-white px-5 py-5 my-5 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
            <Autocomplete
              disablePortal
              id="itemName"
              name="itemName"
              size="small"
              options={itemList}
              getOptionLabel={(option) => option.ItemName}
              renderInput={(params) => (
                <TextField {...params} label="Item Name" />
              )}
            />
            <TextField
              type="text"
              color="primary"
              size="small"
              fullWidth
              id="quantity"
              name="quantity"
              placeholder=""
              label="Quantity"
              variant="outlined"
            />
            <FormControl fullWidth size="small">
              <InputLabel id="measurement">Measure</InputLabel>
              <Select
                native
                labelId="measurement"
                color="primary"
                id="measurement"
                name="measurement"
                label="Measure"
              >
                <option aria-label="None" value="" />
                {Object.keys(measurement).map((item, index) => (
                  <optgroup label={item}>
                    {measurement[item].map((e, index) => (
                      <option value={e.MeasureID}>{e.MeasureName}</option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            </FormControl>
            <TextField
              type="text"
              color="primary"
              size="small"
              fullWidth
              id="cost"
              name="cost"
              placeholder=""
              label="Cost"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center mt-4">
            <FormControlLabel
              control={
                <Checkbox
                  name="categoryRequired"
                  checked={categoryRequired}
                  onChange={handleCategoryRequiredChecked}
                />
              }
              label="Add Tag/Category"
              className="text-nowrap"
            />
            {categoryRequired && (
              <TextField
                type="text"
                color="primary"
                size="small"
                id="category"
                name="category"
                placeholder=""
                label="Tag/Category Name"
                variant="outlined"
              />
            )}
          </div>
        </div>
        <div className="">
          <Button
            className="bg-primary hover:bg-prmary"
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </div>
        <div className='mt-5 shadow w-full lg:w-2/3'>
          <Accordion elevation={0} className=''>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1-content"
              id="panel1-header"
              className='text-xl font-bold'
            >
              <div className='flex items-center'>
                <div>Request Item</div>
                <Tooltip title="Not seeing an item you need to add? Click here to request it to be added. It usually takes about 24 hours to get new items.">
                  <IconButton>
                      <HelpOutlineOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            </AccordionSummary>
            <AccordionDetails className='flex flex-col space-y-2'>
              <TextField
                type="text"
                color="primary"
                size="small"
                id="newItem"
                name="newItem"
                placeholder=""
                label="Item Name"
                variant="outlined"
                onChange={(e) => setNewItem(e.target.value)}
              />
              <Button
                className="bg-primary hover:bg-prmary w-fit"
                variant="contained"
                onClick={handleReqItem}
              >
                Submit
              </Button>
            </AccordionDetails>
          </Accordion>
        </div>
      </form>
      <div className="p-5 shadow rounded-lg bg-gray-50 inventory-wrapper mt-12">
        <div className="text-2xl font-bold mx-2">{header}</div>
        <div className="inv-category mt-5 mx-2 bg-white lg:w-1/3">
          <Autocomplete
            // value={selectedCat}
            onChange={handleChangeCategory}
            multiple
            size="small"
            id="tags-outlined"
            disableCloseOnSelect
            options={category}
            getOptionLabel={(option) => option.CatName}
            // defaultValue={[top100Films[13]]}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.CatName}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Category / Tag" />
            )}
          />
        </div>
        <div className="mt-3">
          <Carousel
            responsive={responsive}
            partialVisbile
            swipeable={true}
            arrows={true}
          >
            {filteredInventory?.map((item, index) => (
              <InventoryCard item={item} key={item.InvID} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}
