import {
  HomeOutlined,
  InventoryOutlined,
  ProductionQuantityLimits,
  ContactSupportOutlined,
  AccountCircleOutlined,
} from '@mui/icons-material'

export const sideBarNavigation = [
  {
    name: 'Dashboard',
    href: '/dashboard',
    icon: HomeOutlined,
  },
  {
    name: 'Inventory',
    href: '/inventory',
    icon: InventoryOutlined,
    type: 'Business'
  },
  {
    name: 'Products',
    href: '/products',
    icon: ProductionQuantityLimits,
    type: 'Business'
  },
  {
    name: 'Cupboard',
    href: '/cupboard',
    icon: InventoryOutlined,
    type: 'Daily'
  },
  {
    name: 'Recipes',
    href: '/recipes',
    icon: ProductionQuantityLimits,
    type: 'Daily'
  },
  {
    name: 'Support',
    href: '/support',
    icon: ContactSupportOutlined,
  },
  {
    name: 'Account',
    href: '/account',
    icon: AccountCircleOutlined
  }
]
