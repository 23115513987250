import { useState } from 'react'

import { Button, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { usePasswordResetMutation } from '../../services/password-reset'

export default function ForgotPassword() {
  const [email, setEmail] = useState()
  const [passwordRest, { isPasswordReseting }] = usePasswordResetMutation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      let resp = await passwordRest({
        body: {
          email,
        },
      }).unwrap()
      if (resp.success) {
        toast.success('Password reset link was sent to your email.')
      } else {
        toast.error(resp.message)
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  }

  return (
    <div className="min-h-full flex flex-col justify-center items-center h-screen sm:px-6 lg:px-8">
      {process.env.REACT_APP_APP_ENV === 'development' && 
        <div className="text-center w-full text-red-500 font-bold fixed !z-[10000] top-0">This is currently in testing. Please submit any feedback or bugs into the Support tab</div>
      }
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <div className="flex flex-row justify-center items-center w-full pb-10 ">
            <img
              src="logo-transparent.png"
              alt="Logo"
              className="h-16 w-auto"
            />
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="mt-1">
              <TextField
                type="text"
                label="Email"
                color="primary"
                size="small"
                fullWidth
                id="email"
                name="email"
                placeholder=""
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
              />
            </div>
            <div>
              <Button
                className="bg-primary hover:bg-primary w-full"
                type="submit"
                variant="contained"
              >
                <Typography className="text-sm font-medium text-white p-1">
                  Send password reset info
                </Typography>
              </Button>
            </div>
            <div className="text-center">
              Already have an account?{' '}
              <a className="text-primary" href="/login">
                Log in
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
